import React, {useEffect, useState} from "react";
import {ScriptCache} from "../../ScriptCache";
import {IPeachnoteViewer} from "./IPeachnoteViewer";
import {IScoreContainerProperties} from "./IScoreContainerProperties";
import {IScoreContainerDispatch} from "./IScoreContainerDispatch";
import {Tracker} from "../../util/Tracker";
import styles from "./ScoreViewerContainer.module.css"

const placeholder = require('../../assets/img/score-viewer-placeholder.jpg');

function ScoreViewerContainer(props: IScoreContainerProperties & IScoreContainerDispatch) {

    const [viewer, setViewer] = useState<any>();
    const [viewerLoaded, setViewerLoaded] = useState<boolean>(false);
    const [viewerInitialized, setViewerInitialized] = useState<boolean>(false);
    const [completelyLoaded, setCompletelyLoaded] = useState<boolean>(false);

    useEffect(() => {
        if (!viewerLoaded && !viewerInitialized && props.showScoreViewer && props.scoreId) {
            console.log('initializing viewer', viewerLoaded, viewerInitialized, props.showScoreViewer, props.scoreId);
            initializeScoreViewer(props);
        }
    }, [viewerLoaded, viewerInitialized, props.showScoreViewer, props.scoreId]);

    useEffect(() => {
        if (viewerLoaded && !props.showScoreViewer) {
            viewer.detach();
        }
    }, [viewerLoaded, props.showScoreViewer]);

    useEffect(() => {
        if (viewerLoaded && props.scoreId) {
            viewer.loadScore(props.scoreId, props.page && props.page - 1);
        }
    }, [viewerLoaded, props.scoreId]);

    useEffect(() => {
        if (viewerLoaded) {
            props.height
            && typeof viewer.setHeight === "function"
            && viewer.setHeight(props.height);

            props.width
            && typeof viewer.setWidth === "function"
            && viewer.setWidth(props.width);

            if (props.page) {
                props.isScoreControlledExternally && viewer.loadPage(props.page);
                if (props.normalizedScoreTime) {
                    const localNormalizedTime = props.normalizedScoreTime / 100 - props.page;
                    viewer.highlightMeasureAtNormalizedTime(localNormalizedTime, props.page, true);
                    if (props.isScoreControlledExternally) {
                        viewer.ensureMeasureVisible(localNormalizedTime, props.page);
                    }
                }
            }
        }
    }, [viewerLoaded]);

    useEffect(() => {
        if (viewer && props.scoreId && typeof props.page !== 'undefined') {
            viewer.loadScore(props.scoreId, props.page && props.page - 1);
        }
    }, [viewerLoaded, props.page]);


    useEffect(() => {
        if (viewerLoaded
            && typeof viewer.setHeight === 'function'
            && props.height) {
            viewer.setHeight(props.height);
        }
    }, [viewerLoaded, props.height]);

    useEffect(() => {
        if (viewerLoaded
            && typeof viewer.setWidth === 'function'
            && props.width) {
            console.log('width', props.width);
            viewer.setWidth(props.width);
        }
    }, [viewerLoaded, props.width]);

    // FIXME: when the viewer is show after being hidden, the page loaded should be the next after the current one
    function initializeScoreViewer(props: IScoreContainerProperties & IScoreContainerDispatch) {
        setViewerInitialized(true);
        const musicWorkId = props.scoreId;

        if (!musicWorkId) {
            console.log('not loading empty score');
            return;
        }

        const scoreViewerLoadingStarted = new Date().getTime();

        new ScriptCache([
            {
                name: '//cdnjs.cloudflare.com/ajax/libs/easyXDM/2.4.20/easyXDM.debug.js',
                callback: () => {
                    // @ts-ignore
                    // window.NSEasyXDM = { easyXDM: window.easyXDM.noConflict("NSEasyXDM") };
                }
            },
            {
                name: 'https://code.jquery.com/jquery-3.3.1.slim.min.js',
                callback: () => {
                    // console.log("loaded jQuery");
                }
            },
            {
                name: 'peachnoteViewer.js',
                callback: () => {
                    console.log("loaded ScoreViewer");
                }
            }
        ], () => {
            setTimeout(() => {
                // @ts-ignore
                window.PeachnoteViewer.initializeViewer({
                    rootElement: "scoreviewerroot",
                    widgetWidth: props.width,
                    widgetHeight: props.height,
                    loadScore: musicWorkId,
                    disableScoreSearch: true,
                    disableControls: true,
                    disableAnnotations: true,
                    readonly: true,
                    chromeless: true,
                    copyrightDisclaimerAcknowledged: true,
                    scoreLoadedCallback: (scoreId: string) => {
                        console.log("loaded score " + scoreId);
                        props && props.onScoreViewerLoaded && !completelyLoaded && props.onScoreViewerLoaded();

                        setCompletelyLoaded(true);

                        Tracker.timing({
                            category: "load_time",
                            variable: "score_viewer",
                            value: new Date().getTime() - scoreViewerLoadingStarted
                        });
                    },
                    measureClickCallback: onScoreMeasureClicked
                }, (viewer: IPeachnoteViewer) => {
                    console.log("Loaded viewer", viewer);
                    setViewer(viewer);
                    setViewerLoaded(true);
                })
            }, 500);
        });
    }

    function onScoreMeasureClicked(args: any) {
        console.log("on score measure clicked", args);
        props.onScoreMeasureClick && props.onScoreMeasureClick(args);
    }

    const isMobile = false;

    const isPortrait = window.innerHeight > window.innerWidth;
    const placeHolderStyle = isMobile ? {
        width: props.width,
        bottom: isPortrait ? props.height / 3 : 0

    } : {
        top: 10
            + (props.height - 30) / 2,
        width: props.width
    };
    return (
        <div
            className={props.showScoreViewer
                ? styles.scoreViewerShownContainer
                : styles.scoreViewerHiddenContainer}
            style={{
                height: props.height,
                width: props.width
            }}>

            {props.showScoreViewer && !completelyLoaded &&
                <img src={placeholder}
                     className={styles.placeholderImage}
                     style={placeHolderStyle}/>}

            <div id="scoreviewerroot"
                 style={{
                     visibility: completelyLoaded ? 'visible' : 'hidden',
                     width: props.width
                 }}/>


        </div>);

}

export default ScoreViewerContainer;
