import React, {useEffect, useState} from 'react';
import {decodeHashParams} from "./util/BrowserUtils";
import fetchJsonp from "fetch-jsonp";
import ScoreViewerContainer from "./components/ScoreViewerContainer";
import {Size, useWindowSize} from "./util/Hooks";

const urlBase = 'https://scoresy-hrd.appspot.com/rest/api/v1/image';

function incipitUrl(hit: ScoreHit, margin: number = 0.01) {
    return `${urlBase}?sid=${hit.score_id}&page=${hit.page}&l=${hit.box.x_min - margin}&t=${hit.box.y_min - margin}&r=${hit.box.x_max + margin}&b=${hit.box.y_max + margin}&w=${200}`;
}

function QueryDisplay(props: { query: SearchQuery, width: number }) {
    const q = props.query;
    const queryImageUrl = `${urlBase}?sid=${q.scoreId}&page=${q.page}&l=${q.x_min}&t=${q.y_min}&r=${q.x_max}&b=${q.y_max}&w=${props.width}`

    return (
        <div className={'w-48 flex flex-row items-center m-8 mt-16 mb-12'}>
            <div className={'mr-8 font-bold'}>
                Query:
            </div>
            <img src={queryImageUrl}/>
        </div>
    );
}

interface SearchStats {
    postprocessing: number
    preprocessing: number
    search: number
    total: number
}

interface SheetAreaSearchResult {
    hits: ScoreHit[]
    stats: SearchStats
}

function SearchHit(props: {
    hit: GroupedSearchHit,
    onSelectScore: (score_id: string, hits: ScoreHit[]) => void,
    onSelectScoreHit: (hit: ScoreHit) => void
}) {
    return (
        <div className={'ml-8 mb-16'}>
            <div className={"font-medium mb-4"}>
                {props.hit.work_title}
            </div>
            <div className={'flex flex-col'}>
                {Object.keys(props.hit.score_hits).map((score_id) => {
                    const max_relevance = 100 * Math.max(...props.hit.score_hits[score_id].map(hit => hit.relevance));
                    return (
                        <div className={'mr-4 flex flex-row mb-4 items-center'} key={score_id}>
                            <a title={`max relevance: ${max_relevance.toFixed(0)}%`}
                               className={"mr-4 w-24"}
                               onClick={() => {
                                   props.onSelectScore(score_id, props.hit.score_hits[score_id])
                               }}>
                                {score_id}
                            </a>
                            <div>
                                {props.hit.score_hits[score_id].map((hit) => {
                                        const relevance = `relevance: ${(100 * hit.relevance).toFixed(0)}%`;
                                        const measures = hit.measures[1] === hit.measures[0]
                                            ? `measure ${hit.measures[0] + 1}`
                                            : `measures ${hit.measures[0] + 1} - ${hit.measures[1] + 1}`;
                                        const staves = hit.staves[1] === hit.staves[0]
                                            ? `staff ${hit.staves[0]}`
                                            : `staves ${hit.staves[0]} - ${hit.staves[1]}`;
                                        const location = `page ${hit.page}, system ${hit.system + 1}, ${measures}, ${staves}`;
                                        return (
                                            <div className={"flex flex-row items-center"}
                                                 key={`${hit.box.x_min}-${hit.box.y_min}`}>
                                                <div className={'w-12'}>
                                                    {`${(100 * hit.relevance).toFixed(0)}%`}
                                                </div>
                                                <img src={incipitUrl(hit)}
                                                     title={location}
                                                     className={"my-2 cursor-pointer"}
                                                     onClick={() => props.onSelectScoreHit(hit)}
                                                />
                                            </div>
                                        )
                                    }
                                )}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    );
}

function HitList(props: {
    hitList: GroupedSearchHit[],
    onSelectScore: (score_id: string, hits: ScoreHit[]) => void,
    onSelectScoreHit: (hit: ScoreHit) => void

}) {
    return (
        <div>
            <div className={'ml-8 w-full border-b-2 mb-4 italic'}>
                Similar places in scores
            </div>
            {props.hitList.map((hit) =>
                <SearchHit hit={hit} key={hit.work_title}
                           onSelectScore={props.onSelectScore}
                           onSelectScoreHit={props.onSelectScoreHit}
                />
            )}
        </div>
    );
}

interface BoundingBox {
    x_min: number
    y_min: number
    x_max: number
    y_max: number
}

interface ScoreHit {
    box: BoundingBox
    work_title: string
    score_id: string
    page: number
    system: number
    measures: number[]
    staves: number[]
    distance: number
    relevance: number
}

function ScoreHitDetailedView(props: { scoreId: string, scoreHits: ScoreHit[], scoreHit?: ScoreHit }) {
    const windowSize: Size = useWindowSize();

    return (
        <div className={'ml-4 p-8'}>
            <ScoreViewerContainer scoreId={props.scoreId}
                                  page={props.scoreHit?.page}
                                  showScoreViewer={true}
                                  showHints={false}
                                  height={600}
                                  width={500}
                                  recNum={0}
                                  toggleScoreViewer={() => {
                                  }}
                                  setScoreConnectedWithGraph={() => {
                                  }}
                                  setScoreConnectedWithPlayer={() => {
                                  }}
                                  closeHints={() => {
                                  }}
                                  isScoreControlledExternally={false}
                                  isScoreConnectedWithGraph={false}
                                  isScoreConnectedWithPlayer={false}
            />
        </div>
    );
}

interface SearchQuery {
    scoreId: string
    page: number
    x_min: number
    x_max: number
    y_min: number
    y_max: number
}

function isQueryValid(query: SearchQuery | undefined) {
    return !(!query || typeof query.scoreId === 'undefined' ||
        typeof query.page === 'undefined' ||
        typeof query.x_min === 'undefined' ||
        typeof query.y_min === 'undefined' ||
        typeof query.x_max === 'undefined' ||
        typeof query.y_max === 'undefined' ||
        query.x_min < 0 || query.y_min < 0 ||
        query.x_max > 1 || query.y_max > 1 ||
        query.page < 0);
}

interface GroupedSearchHit {
    work_title: string
    score_hits: { [score_id: string]: ScoreHit[] }
    relevance: number
}

function App() {
    const [query, setQuery] = useState<SearchQuery>();
    const [queryValid, setQueryValid] = useState<boolean>(false);
    const [hitList, setHitList] = useState<SheetAreaSearchResult>();
    const [groupedHits, setGroupedHits] = useState<GroupedSearchHit[]>();

    const [selectedScoreHits, setSelectedScoreHits] = useState<ScoreHit[]>([]);
    const [selectedScoreId, setSelectedScoreId] = useState<string>();
    const [selectedScoreHit, setSelectedScoreHit] = useState<ScoreHit>();

    const searchStr = window.location.search;
    const searchParams = decodeHashParams(searchStr.slice(1, searchStr.length));


    useEffect(() => {
        setQuery({
            scoreId: searchParams['sid'],
            page: parseInt(searchParams['page']),
            x_min: parseFloat(searchParams['x_min']),
            y_min: parseFloat(searchParams['y_min']),
            x_max: parseFloat(searchParams['x_max']),
            y_max: parseFloat(searchParams['y_max']),
        });
    }, [searchStr]);

    useEffect(() => {
        const searchApiBase = "//search-backend.tuttitempi.com/search/area";
        if (!query) return;
        const url = searchApiBase + "?" +
            "score_id=" + query.scoreId +
            "&page=" + query.page +
            "&x_min=" + query.x_min +
            "&y_min=" + query.y_min +
            "&x_max=" + query.x_max +
            "&y_max=" + query.y_max +
            "&scope=global";

        fetchJsonp(url, {timeout: 30000}).then((response) => response.json().then((data) => {
            setHitList(data);
        }, (err) => console.error(err))).catch((err) => {
            console.error(err)
        });

    }, [query]);

    useEffect(() => {
        if (!hitList) return;
        let groups: { [work_title: string]: GroupedSearchHit } = {};
        for (const hit of hitList.hits) {
            if (groups[hit.work_title] !== undefined) {
                let group = groups[hit.work_title];
                if (groups[hit.work_title].score_hits[hit.score_id] !== undefined) {
                    groups[hit.work_title].score_hits[hit.score_id].push(hit);
                } else {
                    groups[hit.work_title].score_hits[hit.score_id] = [hit];
                }
                groups[hit.work_title].relevance = Math.max(group.relevance, hit.relevance);
            } else {
                let score_hits: { [score_id: string]: ScoreHit[] } = {};
                score_hits[hit.score_id] = [hit];
                groups[hit.work_title] = {work_title: hit.work_title, score_hits, relevance: hit.relevance};
            }
        }
        let sortedGroups = Object.values(groups).sort((a, b) => a.relevance > b.relevance ? -1 : 1);
        setGroupedHits(sortedGroups);
    }, [hitList]);

    useEffect(() => {
        const isValid = isQueryValid(query);
        setQueryValid(isValid);
        if (!isValid) return;

    }, [query?.scoreId, query?.page, query?.x_min, query?.y_min, query?.x_max, query?.y_max]);

    return (
        <div>
            {!queryValid && <div>No query</div>}
            {queryValid && query && <div className={"flex flex-row"}>
              <div className="flex flex-col w-1/2">
                <QueryDisplay query={query} width={200}/>
                  {groupedHits && <HitList hitList={groupedHits}
                                           onSelectScore={(score_id, hits: ScoreHit[]) => {
                                               setSelectedScoreId(score_id);
                                               setSelectedScoreHits(hits);
                                           }}
                                           onSelectScoreHit={(hit: ScoreHit) => {
                                               setSelectedScoreId(hit.score_id);
                                               setSelectedScoreHit(hit);
                                           }}/>}
              </div>
              <div className={"w-1/2 relative"}>
                  {(selectedScoreHits.length > 0 || selectedScoreHit)
                      && selectedScoreId
                      && <div className={'fixed top-0'}>
                        <ScoreHitDetailedView scoreId={selectedScoreId}
                                              scoreHits={selectedScoreHits}
                                              scoreHit={selectedScoreHit}/>
                      </div>}
              </div>
            </div>}
        </div>
    );
}

export default App;
