import ReactGA, {EventArgs, FieldsObject, TimingArgs} from "react-ga";

export class Tracker {

    public static initialize(): void {
        // @ts-ignore
        ReactGA.initialize('UA-9937279-19', {standardImplementation: true});
        ReactGA.ga('set', 'anonymizeIp', true);
        ReactGA.ga('set', 'forceSSL', true);
        // ReactGA.ga('set', 'storage', 'none');
    }

    public static event(args: EventArgs): void {
        ReactGA.event(args);
    }

    public static exception(args: FieldsObject): void {
        ReactGA.exception(args);
    }

    public static timing(args: TimingArgs): void {
        ReactGA.timing(args);
    }

    public static pageview(path: string): void {
        ReactGA.pageview(path);
    }

}